import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import nl from './i18n/nl.json';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { nlBE } from 'date-fns/locale';
setDefaultOptions({ locale: nlBE });

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
  lng: 'nl',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 8000,
        }}
      />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
