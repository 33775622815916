import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { ANONYMOUS_API_URI } from '../config';
import { useTranslation } from 'react-i18next';
import LanguageSelection from '../components/LanguageSelection';
import { toast } from 'react-hot-toast';
import { notify } from '../components/ToastBox';

const ResetPassword = () => {
  const [error, setError] = useState(null);
  const [registration, setRegistration] = useState({});
  const [resetting, setResetting] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const reset = async (e) => {
    e.preventDefault();
    if (registration.gsm?.charAt(0) !== '+') {
      return setError(t('mobileIncorrect'));
    }
    if (registration.password?.length < 6) {
      return setError(t('passwordInvalid'));
    }
    setResetting(true);
    const res = await fetch(`${ANONYMOUS_API_URI}/resetpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        registration,
      }),
    });
    setResetting(false);
    const data = await res.json();
    if (!data?.success) {
      return setError(data.error || 'signupFailed');
    }
    notify(
      t('notification.resetTitle'),
      t('notification.resetText'),
      'success'
    );
    navigate('/signin');
  };

  return (
    <div className="ano-bg">
      <div className="text-center mb-4">
        <span className="font-bold text-lg text-white">
          Gorselé - Exxon Transport
        </span>
      </div>
      <div className="container max-w-md mx-auto flex flex-col bg-white shadow-lg xl:p-12 lg:p-6 p-4 rounded-lg">
        <h1 className="font-bold mb-2">{t('resetpassword.title')}</h1>
        <p className="text-xs">
          Je ontvangt een e-mail met een link om het nieuwe wachtwoord te
          bevestigen.
        </p>
        {error && <p className="text-red-400">{t(`error.${error}`)}</p>}
        <form className="flex flex-col" onSubmit={reset}>
          <label htmlFor="gsm">{t('form.mobile')}</label>
          <input
            id="gsm"
            name="gsm"
            alt="gsm"
            required
            value={registration?.gsm || ''}
            onChange={(e) =>
              setRegistration({ ...registration, gsm: e.currentTarget.value })
            }
            className="rounded border p-2"
          />
          <span className="text-sm text-gray-400 mb-2">
            {t('form.hint.mobile')}
          </span>
          <label htmlFor="email">{t('form.email')}</label>
          <input
            type="email"
            id="email"
            name="email"
            alt="email"
            required
            value={registration?.email || ''}
            onChange={(e) =>
              setRegistration({ ...registration, email: e.currentTarget.value })
            }
            className="rounded border mb-2 p-2"
          />
          <label htmlFor="password">{t('form.password')}</label>
          <input
            type="password"
            id="password"
            name="password"
            alt="password"
            required
            value={registration.password || ''}
            onChange={(e) =>
              setRegistration({
                ...registration,
                password: e.currentTarget.value,
              })
            }
            className="rounded border mb-2 p-2"
          />
          <span className="text-sm text-gray-400 mb-2">
            {t('form.hint.password')}
          </span>
          {resetting ? (
            <Spinner />
          ) : (
            <>
              <input
                type="submit"
                className="styled-btn primary"
                value={t('resetpassword.reset')}
              />
              <Link to="/signup" className="styled-btn cancel">
                {t('signin.signin')}
              </Link>
            </>
          )}
        </form>
      </div>
      <LanguageSelection />
    </div>
  );
};

export default ResetPassword;
