/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/Spinner';
import { PROTECTED_API_URI } from '../config';
import { useHeaders } from '../hooks/useHeaders';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { toast } from 'react-hot-toast';
import authFetchWrapper from '../api/authFetchWrapper';

const HOURSNOSUBSCRIBE = 18;

const MyOrders = () => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [orders, setOrders] = useState();
  const headers = useHeaders(true);

  const listOrders = async () => {
    setFetching(true);
    const res = await authFetchWrapper(
      `${PROTECTED_API_URI}/orders/subscribed`,
      {
        headers,
      }
    );
    setFetching(false);
    const data = await res.json();
    if (data?.success) {
      setOrders(data.orders);
    }
  };

  const unsubscribeFromOrder = async (order) => {
    setFetching(true);
    const res = await authFetchWrapper(
      `${PROTECTED_API_URI}/orders/unsubscribe`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({
          id: order._id,
        }),
      }
    );
    setFetching(false);
    const data = await res.json();
    if (!data?.success) {
      return toast.error(t('notification.unsubscribeFailed'));
    }
    toast.success(t('notification.unsubscribeSuccess'));
    listOrders();
  };

  const subscribe = (order) => {
    Swal.fire({
      title: t('dialog.orders.unsubscribe.title'),
      text: t('dialog.orders.unsubscribe.question'),
      showCancelButton: true,
      cancelButtonText: t('dialog.cancel'),
      confirmButtonText: t('dialog.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        unsubscribeFromOrder(order);
      }
    });
  };

  useEffect(() => {
    if (orders) return;
    listOrders();
  }, []);

  return (
    <div>
      <h1 className="page-title">{t('orders.mytitle')}</h1>
      {fetching ? (
        <Spinner />
      ) : (
        <div className="flex flex-col">
          {!orders?.length ? <p>Geen trajecten gepland</p> : null}
          {orders?.map((order) => (
            <div className="box" key={order._id}>
              <div className="p-4">
                <table width="100%" className="box-table">
                  <tbody>
                    <tr>
                      <td>
                        <span className="box-label">{t('orders.date')}</span>
                      </td>
                      <td>{format(order.execDateUTC, 'dd/MM/yyyy EEEE')}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="box-label">
                          {t('orders.pickuptime')}
                        </span>
                      </td>
                      <td>{order.pickupTime}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="box-label">
                          {t('orders.departure')}
                        </span>
                      </td>
                      <td>
                        {order.cityFrom.city}
                        {order.remarkFrom?.length
                          ? ` (${order.remarkFrom})`
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="box-label">
                          {t('orders.destination')}
                        </span>
                      </td>
                      <td>
                        {order.cityTo.city}
                        {order.remarkTo?.length ? ` (${order.remarkTo})` : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {order.firstPickupTime >
              Date.now() + HOURSNOSUBSCRIBE * 60 * 60 * 1000 ? (
                <button className="box-action" onClick={() => subscribe(order)}>
                  {t('orders.unsubscribe')}
                </button>
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyOrders;
