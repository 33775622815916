import { Route, Routes } from 'react-router-dom';
import SignIn from './views/SignIn';
import Home from './views/Home';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthProvider } from './hooks/useAuth';
import { SideBarProvider } from './hooks/useSideBar';
import SignUp from './views/SignUp';
import AppLayout from './components/AppLayout';
import Orders from './views/Orders';
import SignOut from './views/SignOut';
import MyOrders from './views/MyOrders';
import ResetPassword from './views/ResetPassword';
import Reset from './views/Reset';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/reset/:resetid" element={<Reset />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SideBarProvider>
                <AppLayout />
              </SideBarProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/myorders" element={<MyOrders />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
