import { useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { useAuth } from '../hooks/useAuth';
import { ANONYMOUS_API_URI } from '../config';
import { useTranslation } from 'react-i18next';
import LanguageSelection from '../components/LanguageSelection';

const SignIn = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const { t } = useTranslation();

  const { login } = useAuth();

  const signin = async (e) => {
    e.preventDefault();
    setSigningIn(true);
    const res = await fetch(`${ANONYMOUS_API_URI}/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    setSigningIn(false);
    const data = await res.json();
    if (!data?.token) {
      return setError(data.error || 'signinFailed');
    }
    login(data.token);
  };

  return (
    <div className="ano-bg">
      <div className="text-center mb-4">
        <span className="font-bold text-lg text-white">
          Gorselé - Exxon Transport
        </span>
      </div>
      <div className="container max-w-md mx-auto flex flex-col bg-white shadow-lg xl:p-12 lg:p-6 p-4 rounded-lg">
        <h1 className="font-bold mb-2">{t('signin.title')}</h1>
        {error && <p className="text-red-400">{t(`error.${error}`)}</p>}
        <form className="flex flex-col" onSubmit={signin}>
          <label htmlFor="email">{t('form.email')}</label>
          <input
            type="email"
            id="email"
            name="email"
            alt="email"
            required
            value={email || ''}
            onChange={(e) => setEmail(e.currentTarget.value)}
            className="rounded border mb-2 p-2"
          />
          <label htmlFor="password">{t('form.password')}</label>
          <input
            type="password"
            id="password"
            name="password"
            alt="password"
            required
            value={password || ''}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="rounded border mb-2 p-2"
          />
          {signingIn ? (
            <Spinner />
          ) : (
            <>
              <input
                type="submit"
                className="styled-btn primary"
                value={t('signin.signin')}
              />
              <Link to="/signup" className="styled-btn cancel">
                {t('signin.createAccount')}
              </Link>
              <Link to="/resetpassword" className="styled-btn outlined">
                {t('signin.forgotPassword')}
              </Link>
            </>
          )}
        </form>
      </div>
      <LanguageSelection />
    </div>
  );
};

export default SignIn;
