import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { isValidToken } from './token';

export const ProtectedRoute = ({ children }) => {
  const { jwt } = useAuth();
  if (!isValidToken(jwt)) {
    // user is not authenticated
    return <Navigate to="/signin" />;
  }
  return children;
};
