/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/Spinner';
import { PROTECTED_API_URI } from '../config';
import { useHeaders } from '../hooks/useHeaders';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { toast } from 'react-hot-toast';
import authFetchWrapper from '../api/authFetchWrapper';

const Orders = () => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [orders, setOrders] = useState();
  const headers = useHeaders(true);
  const [search, setSearch] = useState({});
  const [filter, setFilter] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState();

  const listOrders = async () => {
    setFetching(true);
    const res = await authFetchWrapper(`${PROTECTED_API_URI}/orders`, {
      headers,
    });
    setFetching(false);
    const data = await res.json();
    if (data?.success) {
      setOrders(data.orders);
    }
  };

  const subscribeToOrder = async (order) => {
    setFetching(true);
    const res = await authFetchWrapper(
      `${PROTECTED_API_URI}/orders/subscribe`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({
          id: order._id,
        }),
      }
    );
    setFetching(false);
    const data = await res.json();
    if (!data?.success) {
      return toast.error(t('notification.subscribeFailed'));
    }
    toast.success(t('notification.subscribeSuccess'));
    listOrders();
  };

  const subscribe = (order) => {
    Swal.fire({
      title: t('dialog.orders.subscribe.title'),
      text: t('dialog.orders.subscribe.question'),
      showCancelButton: true,
      cancelButtonText: t('dialog.cancel'),
      confirmButtonText: t('dialog.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        subscribeToOrder(order);
      }
    });
  };

  const filterOrders = (e) => {
    e.preventDefault();
    if (Object.getOwnPropertyNames(search)?.length) {
      let newOrders = [...orders];
      if (search.execDate && new Date(search.execDate)) {
        newOrders = newOrders.filter(
          (order) =>
            format(order.execDateUTC, 'dd/MM/yyyy') ===
            format(new Date(search.execDate), 'dd/MM/yyyy')
        );
      }
      if (search.zipFrom?.length) {
        newOrders = newOrders.filter(
          (order) => order.cityFrom?.zip?.toString() === search.zipFrom
        );
      }
      if (search.zipFrom?.length) {
        newOrders = newOrders.filter(
          (order) => order.cityTo?.zip?.toString() === search.zipTo
        );
      }
      setFilter(false);
      setFilteredOrders(newOrders);
    } else {
      setFilteredOrders(undefined);
    }
  };

  const resetFilter = () => {
    setFilter(false);
    setSearch({});
    setFilteredOrders(undefined);
  };

  useEffect(() => {
    if (orders) return;
    listOrders();
  }, []);

  let returnOrders = filteredOrders || orders;

  return (
    <div>
      <h1 className="page-title">{t('orders.title')}</h1>
      {fetching ? (
        <Spinner />
      ) : (
        <div className="flex flex-col">
          <div className="filter-box">
            <div className="title" onClick={() => setFilter(!filter)}>
              <h1>Filter</h1>
              <span className="closebtn">{filter ? '-' : '+'}</span>
            </div>
            <div className={`drawer ${filter ? 'open' : 'closed'}`}>
              <form className="app-form">
                <label>{t('orders.search.date')}</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={search.execDate || ''}
                  onChange={(e) =>
                    setSearch({ ...search, execDate: e.currentTarget.value })
                  }
                  placeholder="dd-mm-yyyy"
                />
                <label>{t('orders.search.pickupzip')}</label>
                <input
                  id="zip"
                  name="zip"
                  value={search.zipFrom || ''}
                  onChange={(e) =>
                    setSearch({ ...search, zipFrom: e.currentTarget.value })
                  }
                />
                <label>{t('orders.search.dropoffzip')}</label>
                <input
                  id="zipTo"
                  name="zipTo"
                  value={search.zipTo || ''}
                  onChange={(e) =>
                    setSearch({ ...search, zipTo: e.currentTarget.value })
                  }
                />
                <button
                  className="app-form-action"
                  onClick={(e) => filterOrders(e)}
                >
                  Filter
                </button>
              </form>
            </div>
          </div>
          {filteredOrders ? (
            <button
              className="app-form-action bg-red-400 mb-4"
              onClick={resetFilter}
            >
              Verwijder filter
            </button>
          ) : null}
          {!returnOrders?.length ? <p>Geen trajecten beschikbaar</p> : null}
          {returnOrders?.map((order) => (
            <div className="box" key={order._id}>
              <div className="p-4">
                <table width="100%" className="box-table">
                  <tbody>
                    <tr>
                      <td>
                        <span className="box-label">{t('orders.date')}</span>
                      </td>
                      <td>{format(order.execDateUTC, 'dd/MM/yyyy EEEE')}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="box-label">
                          {t('orders.pickuptime')}
                        </span>
                      </td>
                      <td>{order.pickupTime}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="box-label">
                          {t('orders.departure')}
                        </span>
                      </td>
                      <td>
                        {order.cityFrom.city}
                        {order.remarkFrom?.length
                          ? ` (${order.remarkFrom})`
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="box-label">
                          {t('orders.destination')}
                        </span>
                      </td>
                      <td>
                        {order.cityTo.city}
                        {order.remarkTo?.length ? ` (${order.remarkTo})` : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button className="box-action" onClick={() => subscribe(order)}>
                {t('orders.subscribe')}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Orders;
