import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="page-title">{t('home.title')}</h1>
      <p className="whitespace-pre-line">{parse(t('home.welcome'))}</p>
    </div>
  );
};

export default Home;
