/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { ANONYMOUS_API_URI } from '../config';
import { toast } from 'react-hot-toast';
import { notify } from '../components/ToastBox';

const Reset = (props) => {
  let { resetid } = useParams();
  const [resetting, setResetting] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const performReset = async () => {
    setResetting(true);
    console.log('tryng to reset');
    console.log(resetid);
    const res = await fetch(`${ANONYMOUS_API_URI}/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: resetid,
      }),
    });
    setResetting(false);
    const data = await res.json();
    if (!data?.success) {
      toast.error(t(`error.${data.error || 'resetInvalid'}`));
      return navigate('/signin');
    }
    notify(
      t('notification.resetSuccessTitle'),
      t('notification.resetSuccessText'),
      'success'
    );
    navigate('/signin');
  };

  useEffect(() => {
    if (resetid) {
      performReset();
    }
  }, [resetid]);

  return (
    <div className="ano-bg text-center">
      {resetting ? (
        <Spinner />
      ) : (
        <p className="text-white text-xl mt-6 font-bold">Reset code invalid</p>
      )}
    </div>
  );
};

export default Reset;
