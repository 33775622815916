/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { createContext, useContext, useMemo } from 'react';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [jwt, setJWT] = useLocalStorage('exxon-app-jwt', null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (token) => {
    setJWT(token);
    navigate('/');
  };

  // call this function to sign out logged in user
  const logout = () => {
    setJWT(null);
    navigate('/signin', { replace: true });
  };

  const value = useMemo(
    () => ({
      jwt,
      login,
      logout,
    }),
    [jwt]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
