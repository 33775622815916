import { GlobeAltIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

const LanguageSelection = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    if (i18n.language !== lng) {
      i18n.changeLanguage(lng);
    }
  };

  return (
    <div className="flex flex-row justify-center text-white mt-4">
      <GlobeAltIcon className="w-4 mr-2" />
      <button className="mr-4" onClick={() => changeLanguage('nl')}>
        Nederlands
      </button>
      <button onClick={() => changeLanguage('en')}>English</button>
    </div>
  );
};

export default LanguageSelection;
