import { Bars4Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { useSideBar } from '../hooks/useSideBar';

const AppHeader = () => {
  const { sideBarOpen, toggleSidebar } = useSideBar();
  return (
    <div className="min-w-full h-12 lg:h-20 bg-red-600 border-b-2 flex items-center p-2 justify-between lg:justify-end">
      <div className="flex justify-start lg:justify-end px-4">
        <span className="text-white font-bold lg:text-3xl">
          GORSELE | EXXON
        </span>
      </div>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-red-600 p-2 text-white hover:bg-red-700 hover:text-white focus:outline-none"
          onClick={toggleSidebar}
        >
          <span className="sr-only">Open main menu</span>
          {sideBarOpen ? (
            <XMarkIcon className="w-4" />
          ) : (
            <Bars4Icon className=" w-4" />
          )}
        </button>
      </div>
    </div>
  );
};

export default AppHeader;
