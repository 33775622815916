import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  StopCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { toast } from 'react-hot-toast';

const ToastBox = ({ id, title, text, icon, visible }) => {
  return (
    <div
      className={`${
        visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 p-4`}
    >
      <div className="flex">{icon}</div>
      <div className="flex-1 px-2">
        {title && <p className="font-bold">{title}</p>}
        {text && <p>{text}</p>}
      </div>
      <div onClick={() => toast.dismiss(id)}>
        <XMarkIcon className="w-6" />
      </div>
    </div>
  );
};

export const notify = (title, text, type) => {
  let icon = <QuestionMarkCircleIcon />;
  switch (type) {
    case 'success':
      icon = <CheckCircleIcon className="text-green-500 w-8" />;
      break;
    case 'warning':
      icon = <ExclamationTriangleIcon className="text-yellow-500 w-8" />;
      break;
    case 'danger':
      icon = <StopCircleIcon className="text-red-500 w-8" />;
      break;
    case 'info':
      icon = <InformationCircleIcon className="text-blue-500 w-8" />;
      break;
    default:
      break;
  }
  toast.custom((t) => (
    <ToastBox
      id={t.id}
      title={title}
      text={text}
      icon={icon}
      visible={t.visible}
    />
  ));
};
