import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import AppHeader from './AppHeader';

const AppLayout = () => {
  return (
    <div className="min-w-full min-h-screen flex flex-col items-end">
      <Sidebar />
      <AppHeader />
      <div className="min-h-full min-w-full lg:min-w-[70vw] lg:max-w-[70vw]">
        <div className="p-4">{<Outlet />}</div>
      </div>
    </div>
  );
};

export default AppLayout;
