import { Link } from 'react-router-dom';
import { useSideBar } from '../hooks/useSideBar';
import { useAuth } from '../hooks/useAuth';
import {
  ArrowRightOnRectangleIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { getUserForToken } from '../token';

const NavigationItems = [
  {
    url: '/orders',
    icon: ShoppingCartIcon,
    label: 'orders',
  },
  {
    url: '/myorders',
    icon: ShoppingBagIcon,
    label: 'myOrders',
  },
];

const Sidebar = () => {
  const { sideBarOpen, toggleSidebar } = useSideBar();
  const { jwt } = useAuth();
  const { logout } = useAuth();
  const { t } = useTranslation();
  return (
    <div
      className={`top-0 -left-[80vw] w-[80vw] lg:-left-[30vw] lg:w-[30vw] bg-red-700 pt-4 lg:pt-6 p-4 pl-6 text-white fixed h-full z-40 flex flex-col ease-in-out duration-300 ${
        sideBarOpen ? 'translate-x-full' : 'translate-x-0 lg:translate-x-full'
      }`}
    >
      {jwt ? (
        <span className="text-sm lg:text-xl mb-6 lg:mb-8">
          {getUserForToken(jwt).fullName}
        </span>
      ) : null}
      <ul>
        {NavigationItems.map((item, i) => {
          const Icon = item.icon;
          return (
            <li key={i} className="mb-4">
              <Link
                to={item.url}
                className="text-red-200 flex items-center lg:text-xl"
                onClick={toggleSidebar}
              >
                <Icon className="w-6 mr-2 lg:w-8 lg:mr-4" />
                <span>{t(`navigation.${item.label}`)}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="flex-1" />
      <div>
        <button
          className="flex items-center text-red-200 lg:text-xl"
          onClick={logout}
        >
          <ArrowRightOnRectangleIcon className="w-6 mr-2 lg:w-8 lg:mr-4" />
          <span>{t('sidebar.signout')}</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
