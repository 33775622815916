/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState } from 'react';
const SideBarContext = createContext();

export const SideBarProvider = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  // call this function when you want the sidebar to open/close
  const toggleSidebar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const value = useMemo(
    () => ({
      sideBarOpen,
      toggleSidebar,
    }),
    [sideBarOpen]
  );
  return (
    <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
  );
};

export const useSideBar = () => {
  return useContext(SideBarContext);
};
